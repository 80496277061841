function AddBathroomErrorPage () {

return (
    <div className="qr-container">
<img src="https://media.giphy.com/media/Dxcny6WwMO43du9QRu/giphy.gif" height="150px"/>
<h2>Oh, crap!</h2>
<h4>We're experiencing technical difficulties with this page.</h4>
<h4>Check back in a few days once we've fixed our plumbing!</h4>
</div>
)
}

export default AddBathroomErrorPage;